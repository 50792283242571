<template>
  <div class="p-grid p-fluid dashboard">
    <div class="p-col-12">
      <div class="card">
        <h5>Recente Bestellingen</h5>
        <DataTable :value="recentOrders" :loading="recentOrders === null" :paginator="recentOrders && recentOrders > 5" :rows="5" class="p-datatable-customers">
          <Column field="createdAt" header="Datum"></Column>
          <Column field="completed" header="Status">
            <template #body="{data}">
              <span :class="`customer-badge status-${data.completed ? 'completed' : 'in-progress'}`">{{data.completed ? 'Ontvangen' : 'In behandeling'}}</span>
            </template>
          </Column>
          <template #footer>
            <Button icon="pi pi-search" iconPos="right" type="button" class="p-button-success" label="Bekijk alle bestellingen" @click="$router.push({ name: 'orders' })" />
          </template>
          <template #empty>
            Geen recente bestellingen gevonden
          </template>
          <template #loading>
            Bezig met laden van recente bestellingen, even geduld aub...
          </template>
        </DataTable>
      </div>
    </div>

    <div class="p-col-12">
      <div class="card">
        <h5>Mijn bestellijsten</h5>
        <DataTable :value="orderLists" class="p-datatable-customers" :loading="orderLists === null">
          <Column field="name" header="Naam"></Column>
          <Column header="Aantal producten">
            <template #body="{data}">
              {{data.items.length}}
            </template>
          </Column>
          <Column header="Bekijk/bestel">
            <template #body="{data}">
              <Button icon="pi pi-search" type="button" class="p-button-success p-mr-2 p-mb-1" @click="$router.push({ name: 'orderList', params: {id:data.id} })"></Button>
            </template>
          </Column>
          <template #empty>
            Geen bestellijst gevonden
          </template>
          <template #loading>
            Bezig met laden van bestellijsten, even geduld aub...
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import Translate from '../mixins/Translate.js'

export default {
  mixins: [Translate],
  data() {
    return {
      recentOrders: null,
      orderLists: null
    }
  },
  mounted() {
    this.$root.orderService.getRecentOrders().then(data => this.recentOrders = data);
    this.$root.orderListService.getOrderLists().then(data => this.orderLists = data);
  }
}
</script>

<style lang="scss" scoped>
.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-completed {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-in-progress {
    background: #FFD8B2;
    color: #805B36;
  }
}
</style>

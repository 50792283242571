export default {
    methods: {
        formatCurrency(value, withSymbol = true) {
            value = Math.round(value)
            if (withSymbol) {
                return (value / 100).toLocaleString('nl-NL', {style: 'currency', currency: 'EUR'});
            }
            return (value / 100).toLocaleString('nl-NL', { minimumFractionDigits: 2 });
        }
    }
}
